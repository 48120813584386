$blueWever: #0d9fdb;
$darkPink: pink;
$green: green;

// use ./node_modules prefix for compatibility with babel compilation, ~ alias is webpack specific
@import './node_modules/bootstrap/scss/bootstrap.scss';
@import './layer';
@import './maps';
@import './layer-side-bar.scss';
@import './layer-parameters.scss';
@import './actions-bar.scss';
@import './filter.scss';
@import './common.scss';
@import './map-legend.scss';
@import '~leaflet/dist/leaflet.css';

button:disabled {
  cursor: not-allowed !important;
}

.hover {
  &-grey-1:hover {
    background-color: #ddd;
    border-radius: 5px;
  }
}

.color {
  &-bluewever {
    &-bg {
      background-color: $blueWever;
    }

    &-text {
      color: $blueWever;
    }
  }
  &-default {
    &-bg {
      background-color: #172b4d;
    }

    &-text {
      color: #172b4d;
    }
  }
}

.map {
  &Container {
    height: 100vh;
    position: relative;
    overflow: hidden;

    .icon {
      height: auto !important;
    }

    .no-padding {
      padding: 0;
    }

    .overflow-unset {
      overflow: unset !important;
    }
  }

  &Margin {
    &Left {
      &1 {
        margin-left: 1em;
      }
    }
  }

  &Draggable.hidden {
    display: 'none';
    z-index: -10;
  }

  &Draggable {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1200;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: 50px;
    min-width: 200px;
    box-shadow: 0px 0px 8px 1px #656565;

    &Title {
      border-bottom: 1px solid #ccc;
      width: 100%;
      margin: 0;

      .col {
        display: flex !important;
        justify-content: center !important;
        text-align: center;
      }
    }

    &Content {
      width: 100%;
      margin: 0;
    }

    &Content.hidden {
      display: none;
    }
  }
}
