.map {
  &Legend {
    &Color {
      width: '14px';
      height: '10px';
    }

    &User {
      z-index: 1000;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px;
      box-shadow: 0px 0px 8px 1px #656565;
      border-radius: 5px;
    }

    &Background {
      background-color: white;
      border-radius: 10px;
      opacity: 0.5;
      filter: blur(5px);
      backdrop-filter: blur(5px);
      z-index: 1011;
    }

    &Info {
      z-index: 1012;
      color: black;
      max-height: 100%;

      h6 {
        text-align: center;
      }
    }
  }
}
