.maps {
  &Leaflet {
    height: 100%;
  }

  &Leaflet.leaflet-container {
    height: 100%;
    margin-bottom: auto;
    margin-left: auto;
    width: 100%;
  }

  &LeafletSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.leaflet-control-attribution {
  display: none;
}

.map {
  &Container {
    height: 100%;
  }

  div.custom-icon {
    position: relative;
  }

  &Point {
    &Camembert {
      position: relative;
      margin-top: -40%;
      margin-left: -40%;
    }
  }

  &Popover {
    &Legend h6 {
      text-align: left;
    }
  }

  &ZoomControl {
    position: absolute;
    left: 25px;
    top: 10px;
    height: 20px;
    z-index: 1002;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    text-align: center;
    width: 80px;

    &Out,
    &In {
      height: 100%;
      padding: 5px;
      font-size: 0.7em;
      list-style: none;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    &Out:hover,
    &In:hover {
      background-color: #ccc;
    }

    &Out {
      border-left: 1px solid #ccc;
    }
  }

  &Compass {
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 500;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.editorapp_marker {
  font-size: 2.2em;
  position: absolute;
  top: -16px;
  left: -4px;
}

.dynamic-components-card-content {
  position: absolute !important;
  left: 50%;
  top: 40px;
  transform: translate(-50%);
  z-index: 500;
  min-width: 20%;
  min-height: 100px;
  display: flex;
  justify-content: center;

  button.start:hover,
  button.start:focus,
  button.end:hover,
  button.end:focus {
    background-color: #eee;
  }
}

.dynamic-components {
  .container-global-home-work {
    position: absolute;
    top: 80px;
    right: 10px;
    z-index: 400;
    min-width: 250px;
  }
  .container-home-work-path {
    position: relative;
    min-width: 140px;
    max-width: 140px;
    display: flex;
    justify-content: flex-end;

    .validate {
      position: absolute;
      top: 0;
      left: 0px;
      background-color: white;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      z-index: 399;
      border: 1px solid #ccc;
      padding-right: 25px;
      height: 35px;
    }

    .home-work-path {
      background-color: white;
      border: 1px solid #ccc;
      z-index: 400;
      position: relative;
      height: 35px;
      width: 110px;

      button {
        cursor: pointer;
      }

      .start {
        color: $darkPink;

        &:hover {
          color: rgb(218, 111, 129);
        }
      }

      .end {
        color: $green;

        &:hover {
          color: rgb(14, 181, 14);
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .mapsLeaflet {
    max-height: 400px;
  }

  .mapLayerSideBar {
    max-width: 175px;
  }

  .minimap {
    display: none;
  }

  .dynamic-components {
    display: none;
  }
}
